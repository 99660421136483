.bg-trans {
  background-color: rgba(255, 255, 255, 0) !important;
  color: black !important;
  outline: none !important;
  border: none !important;
  text-align: start !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-trans :hover {
  outline: none !important;
  border: none !important;
}
.overFlowX {
  overflow-x: scroll;
}

.big-str {
  letter-spacing: 0.2px !important;
  white-space: nowrap !important;
  width: 200px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.profileHead {
  padding: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.left {
  width: 40%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.left img {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 4px solid transparent;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, rgb(255, 209, 5), rgb(95, 12, 250));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.right {
  width: 52%;
}
.right form .inputRow {
  padding: 0.5rem 0.2rem;
}
.dflex {
  display: flex;
}
.down {
  padding: 0.5rem 0.2rem;
}

.addressPre {
  padding: 1rem;
  border: 1px solid rgb(235, 231, 231);
  width: 40%;
  border-radius: 12px;
}

h5 {
  font-style: italic !important;
  color: black;
}
.text-cyan {
  color: rgb(10, 196, 196);
}

.addressDetails {
  padding: 0.2rem 0.5rem;
  margin: 0.6rem 0.7rem;
  border: 1px solid rgb(231, 231, 231);
  width: fit-content;
  font-size: 15px;
  cursor: pointer;
  transition: 500ms;
  border-radius: 7px;
  color: rgb(70, 67, 67);
}
.indicate {
  color: rgb(6, 66, 177);
  font-weight: 600;
  font-size: 16px;
}

.addressDetails:hover {
  border: 1px solid rgb(197, 197, 197);
  color: black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.selectedAddress {
  padding: 0.2rem 0.5rem;
  width: fit-content;
  font-size: 15px;
  border-radius: 7px;
  margin: 0.6rem -0.01rem;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  border: 1.5px solid transparent;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, rgb(195, 0, 255), rgb(255, 217, 0));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.submitButton {
  width: 100%;
  margin-top: 0.4rem;
}
.addressEditForm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addressEditForm form {
  padding: 1rem 4rem;
  background-color: rgb(245, 245, 245);
  border-radius: 15px;
  margin-left: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.addressEditForm form .inputRow {
  padding: 0.5rem 0rem;
}
.m-1 {
  padding: 0.3rem 0.1rem;
}

.addressContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 34vh;
}
.addressContainer::-webkit-scrollbar {
  display: none;
  opacity: 0;
}
.justify-content-space-evently {
  justify-content: space-evenly;
}
.aligin_item_center {
  align-items: center;
}
.small_spinner {
  width: 1rem !important;
  height: 1rem !important;
}
.changeStatusBtn {
  min-width: 8rem;
}

@media (max-width: 560px) {
  .modal-dialog {
    width: 100% !important;
  }
  table {
    width: 100vw;
  }
  .down,
  .profileHead {
    flex-direction: column;
  }
  .addressPre,
  .left,
  .right {
    width: 100%;
  }
}

.dayCountOuter {
  background-color: #f8f8f8;
  width: 100%;
  padding: 0% 2rem;
  margin-top: 3rem;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.dayCountOuter .submitButton {
  width: 100%;
}

body {
  font-family: sans-serif;
  font-weight: 100;
  --grey-100: #e4e9f0;
  --grey-200: #cfd7e3;
  --grey-300: #b5c0cd;
  --grey-800: #3e4e63;
  --grid-gap: 1px;
  --day-label-size: 20px;
}

ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.calendar-month {
  position: relative;
  background-color: var(--grey-200);
  border: solid 1px var(--grey-200);
  padding: 0rem 2rem;
}

.calendar-month-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

.calendar-month-header-selected-month {
  font-size: 24px;
  font-weight: 600;
  padding: 1rem;
}

.calendar-month-header-selectors {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#present-month-selector {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  color: #3e4e63;
}

.calendar-month-header-selectors > * {
  cursor: pointer;
}

.day-of-week {
  color: var(--grey-800);
  font-size: 18px;
  background-color: #fff;
  padding-bottom: 5px;
  padding-top: 10px;
}

.day-of-week,
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  text-align: right;
  padding-right: 5px;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  color: #3e4e63;
}

.days-grid {
  height: 100%;
  position: relative;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  border-top: solid 1px var(--grey-200);
}

.calendar-day {
  position: relative;
  min-height: 100px;
  font-size: 16px;
  background-color: #807d7d28;
  color: var(--grey-800);
  padding: 5px;
  cursor: pointer;
  user-select: none;
  transition: 200ms;
}
.calendar-day:hover {
  background-color: #2a3b8688;
  color: white;
}

.bg_grey {
  background-color: #fff;
}

.cursur_disable {
  cursor: not-allowed;
}
.small {
  font-size: 11px !important;
}
.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2px;
  padding: 2rem;
  font-weight: 500;
  font-size: 20px;
  width: var(--day-label-size);
  height: var(--day-label-size);
}
.xxx {
  width: 60%;
}
.upCommingDay {
  background-color: rgb(230, 235, 240);
  cursor: progress;
}

.currentDay {
  background-color: rgb(0, 0, 0);
}

.dateSelector {
  outline: none;
  border: none;
  padding: 0.4rem 1rem;
  border: 1px solid #555f6b;
  border-radius: 5px;
}

/* ----------------------- */
.vechile {
  display: flex;
}

.details {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
}

.details {
  font: 19px;
  font-weight: 600;
  color: #555f6b;
}
.details .title {
  font-size: 20px;
  font-weight: 800;
  text-transform: capitalize;
  color: #a0a0a0;
}
.ml-4 {
  margin-left: 5%;
}
.bg-image {
  background-image: url("https://miro.medium.com/max/600/0*CQ7iTACU9P6AEoTV.jpg");
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover; */
}
.packgeOuter {
  width: 100%;
}
.allServices {
  display: flex;
  justify-content: space-evenly;
}
.services {
  background: #555f6b;
  width: fit-content;
  padding: 1rem;
  backdrop-filter: blur(5px) saturate(200%);
  -webkit-backdrop-filter: blur(5px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: fit-content;
  max-width: 50%;
  font-weight: 500;
  max-height: 60vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
}

/* width */
.services::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.services::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 1px;
}

/* Handle */
.services::-webkit-scrollbar-thumb {
  background: #555f6b;
  border-radius: 10px;
}
.services p {
  border: 1px solid #555f6b3a;
  padding: 0.1rem 0.5rem;
  border-radius: 9px;
  color: black;
  width: fit-content;
  margin-left: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.services .options {
  background-color: #555f6b;
  width: fit-content;
  margin-left: 10px;
  color: white;
  font-weight: 900;
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
}
.services span {
  position: absolute;
  background-color: #555f6b;
  padding: 0.2rem;
  border-radius: 50%;
  left: 0.5%;
  color: white;
}

.services h3 {
  background-color: #8d7bfd;
  width: fit-content;
  color: white;
  padding: 0.1rem 0.4rem;
  border-radius: 9px;
}
