.highlight {
  color: red;
  background-color: blue;
}

.calender-wrapper {
  box-sizing: border-box;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 600px;
  flex-wrap: nowrap;
  .dayz {
    flex: 1;
  }
}
